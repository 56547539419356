import {EagleApiInterface, EagleModuleApiInterface} from 'kernel/api/api'

export default (api: EagleApiInterface) => {
  api.collection.productCategoryApi = <EagleModuleApiInterface>{
    create: (id: string, data: AnyObject) => api.request({
      url: `/api/admin/product-category/${id}`,
      type: 'post',
      params: data,
    }),
    update: (id: string, data: AnyObject) => api.request({
      url: `/api/admin/product-category/${id}/update`,
      type: 'put',
      params: data,
    }),
    tree: () => api.request({
      url: `/api/admin/product-category/tree`,
      type: 'get',
    }),
    flatTree: () => api.request({
      url: `/api/admin/product-category/flat-tree`,
      type: 'get',
    }),
    move: (from: string, to: string, mode: 'before'|'after'|'inside') => api.request({
      url: `/api/admin/product-category/move`,
      type: 'put',
      params: {
        from,
        to,
        mode,
      }
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/product-category/${id}`,
      type: 'delete',
    }),
  }
  
  api.collection.productApi = <EagleModuleApiInterface>{
    read: (id: string) => api.request({
      url: `/api/admin/product/${id}`,
      type: 'get',
    }),
    index: (params = {}) => api.request({
      url: `/api/admin/product/index`,
      type: 'get',
      params,
    }),
    create: (params = {}) => api.request({
      url: `/api/admin/product`,
      type: 'post',
      params,
    }),
    update: (id: string, params = {}) => api.request({
      url: `/api/admin/product/${id}/update`,
      type: 'put',
      params,
    }),
    clone: (id: string, params = {}) => api.request({
      url: `/api/admin/product/${id}/clone`,
      type: 'post',
      params,
    }),
    patch: (id: string, column: string, value: any) => api.request({
      url: `/api/admin/product/${id}`,
      type: 'patch',
      params: {
        column,
        value,
      },
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/product/${id}`,
      type: 'delete',
    }),
    batch: (targets: string[], column: string, value: any) => api.request({
      url: `/api/admin/product/batch`,
      type: 'patch',
      params: {
        ids: targets,
        column,
        value,
      },
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/product/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }
}
